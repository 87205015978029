import React, {useState, useEffect} from 'react';
import { useParams } from "react-router-dom";
import { Url } from '../global';

//FORM INPUT
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';


//MODAL 
import Modal from '@mui/material/Modal';

//ICON
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import CloseIcon from '@mui/icons-material/Close';

// STYLE
import './DoctorViewer.css';


const DoctorViewer = (props) => {    
    const path = "assets/doctor/" + props.data.photo;
    // console.log(props.qualification)
    return(
    <Modal
        open={props.open}
        onClose={props.handleCloseDoctorViewer}  
        aria-labelledby="Doctor Viewer"
        aria-describedby="Doctor Viewer"
        className="ModalDoctorStyle"
    >
        <div className="ModalDoctorContainer">
            <div className = "TitleBar">
                <IconButton aria-label="close" style={{borderRadius:"0"}} onClick={props.handleCloseDoctorViewer}>
                    <CloseIcon />    
                </IconButton>
            </div>

            <div className="ControlContainer">
                <div className="DoctorPhotoContainer">
                    <div className='DoctorPhoto'
                        style={{ 
                                backgroundImage: `url( ${path} )` }}>
                    </div>
                </div>
            </div>

            <div className="ModalContent">
                <IconButton aria-label="like">
                    <FavoriteIcon />    
                </IconButton>
                
                <IconButton aria-label="share">
                    <ShareIcon />
                </IconButton>

                <h3>{props.data.name}</h3> 
                <p className="Specialist">Spesialis {props.data.specialist}</p>
                <p style={{fontWeight: 'bold'}}>Pendidikan :</p>
                
                {
                    props.qualification.map((row, index)=>{
                            return <ul style={{color: 'gray'}}>- {row.value}</ul>
                        }
                    )
                }

                <div className="DoctorScheduleWeek">
                    <div><div className="ScheduleWeekHeader">Senin</div>  <div className="trow">{props.data.monday}</div></div>
                    <div><div className="ScheduleWeekHeader">Selasa</div> <div className="trow">{props.data.tuesday}</div></div>
                    <div><div className="ScheduleWeekHeader">Rabu</div>   <div className="trow">{props.data.wednesday}</div></div>
                    <div><div className="ScheduleWeekHeader">Kamis</div>  <div className="trow">{props.data.thursday}</div></div>
                    <div><div className="ScheduleWeekHeader">Jumat</div>  <div className="trow">{props.data.friday}</div></div>
                    <div><div className="ScheduleWeekHeader">Sabtu</div>  <div className="trow">{props.data.saturday}</div></div>
                    <div><div className="ScheduleWeekHeader">Minggu</div> <div className="trow">{props.data.sunday}</div></div>
                </div>

            </div>

            
            

        </div>
    </Modal>
    );
   
  }
  
  
  export default DoctorViewer;
  