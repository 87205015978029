// never used
// import react from "react";
// import logo from './logo.svg';
// import './App.css';

import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import {
   BrowserRouter as Router,
   Routes,
   Route,
   Link,
   useNavigate
 } from "react-router-dom";

import './index.css';
import NavBar from './page/NavBar';
import Carousel from './page/Carousel2';
import LoginWithGoogle from './page/LoginWithGoogle'
import Information from "./page/Information";
import InformationViewer from "./page/InformationViewer";
import Video from "./page/Video";

import {Tabs} from "./page/Tab";
import Booking from "./page/Booking";
import BookingPatientDt from "./page/BookingPatientDt";
import PatientBookingHistory from "./page/PatientBookingHistory";
import Login from "./page/Login";
import Profile from "./page/Profile";
import Awards from "./page/Awards";
import Partner from "./page/Partner";
import Policlinic from "./page/Policlinic";
import Igd from "./page/Igd";
import InPatient from "./page/InPatient";
import MotherChild from "./page/MotherChild";
import Lab from "./page/Lab";
import InformationGallery from "./page/InformationGallery";
import VideoGallery from "./page/VideoGallery";
import BookingRule from "./page/BookingRule";
import Contact from "./page/Contact";
import Career from "./page/Career";


import Footer from "./page/Footer";
import UnderConstruction from "./page/UnderConstruction";



// import Button from '@mui/material/Button';
// import { DataGrid, GridRowsProp, GridColDef  } from '@mui/x-data-grid';
// import type {} from '@mui/x-data-grid/themeAugmentation';
// import type {} from '@mui/x-data-grid-pro/themeAugmentation';

// import Container from '@mui/material/Container';
// import Stack from '@mui/material/Stack';
// import Grid from '@mui/material/Grid';
// import ResponsiveNavBar from './page/ResponsiveNavBar';
// import NavBar from './ResponsiveNavBar';
// import { Carousel } from 'antd';
// import 'antd/dist/antd.css';


//--COMPONENT
import Schedule from './page/Schedule.jsx';

  
const App =(props)=> {
   const [login, setLogin] = useState(false);
   const [openLogin, setOpenLogin] = useState(false);
   const [openInformation, setOpenInformation] = useState(false);
   const [loginSource, setLoginSource] = useState();
   const [id, setId] = useState('');
   const [phone, setPhone] = useState('');


   const handleLogin = () => {
      setLogin(true);
   }

   const handleLogin_Click = ()=>{
       setOpenLogin(true);
       setLoginSource("icon");
   }
   
   const handleLogin_Close = ()=>{
      setOpenLogin(false);
      setId('');
      setPhone('');
   }

   const HandleLoginTab_Click = ()=>{
      setOpenLogin(true);
      setLoginSource("tab");
   }

   const handleId_Change = (event)=>{
      setId(event.target.value)
   }

   const handlePhone_Change = (event)=>{
      setPhone(event.target.value)
   }


   return(
            <div className="Background">
        
            <Router>
                 

               <Login openLogin={openLogin} 
               loginSource={loginSource}
               handleLogin={handleLogin}
               id={id}
               phone={phone}
               handleId_Change={handleId_Change}
               handlePhone_Change={handlePhone_Change}
               handleLogin_Close={handleLogin_Close}/>

               <div className="Container">
               <Routes>
                     {/* home */}
                     <Route path="" element={<><NavBar HandleLogin_Click= {handleLogin_Click}  /><Carousel/><Tabs handleLoginTab_Click={HandleLoginTab_Click} login={login} id={id} phone={phone}/><Information/><Video/><Footer/> </>}></Route>

                     {/* login with google */}
                     <Route path="/login" element={<> <Login id={id} phone={phone} loginSource={loginSource} openLogin={openLogin} handleLogin={handleLogin} handleLogin_Close={handleLogin_Close} handleId_Change={handleId_Change} /></>}></Route>

                     {/* home with login param */}
                     <Route path="/grahahermine/:id&:phone" element={<><NavBar HandleLogin_Click= {handleLogin_Click}  /><Carousel/><Tabs handleLoginTab_Click={HandleLoginTab_Click} login={login}/><Information/><Video/><Footer/> </>}></Route>
                     
                     {/* check doctor schedule */}
                     <Route path="/schedule" element={<><NavBar HandleLogin_Click= {handleLogin_Click}  /><Schedule/><Footer/></>}></Route>
                     
                     {/* booking choose doctor */}
                     <Route path="/booking/:id" element={<><NavBar HandleLogin_Click= {handleLogin_Click}  /><Booking/><Footer/></> }></Route>

                     {/* booking patient data */}
                     <Route path="/bookingpatientdt/:id&:date&:time" element={<><NavBar HandleLogin_Click= {handleLogin_Click}  /><BookingPatientDt login = {login} hLogin = {handleLogin} /><Footer/></> }></Route>

                     {/* display patient data */}
                     <Route path="/patient/:id&:phone" element={<><NavBar HandleLogin_Click= {handleLogin_Click}  /><PatientBookingHistory login={login}/><Footer/></> }></Route>

                     {/* profile */}
                     <Route path="/profile" element={<><NavBar HandleLogin_Click= {handleLogin_Click}  /><Profile/><Awards/><Footer/></>}></Route>

                     {/* award */}
                     <Route path="/award" element={<><NavBar HandleLogin_Click= {handleLogin_Click}  /><Awards/><Footer/></>}></Route>


                     <Route path="/partner" element={<><NavBar HandleLogin_Click= {handleLogin_Click}  /><Partner/><Footer/></>}></Route>

                     <Route path="/policlinic" element={<><NavBar HandleLogin_Click= {handleLogin_Click}  /><Policlinic/><Footer/></>}></Route>

                     <Route path="/igd" element={<><NavBar HandleLogin_Click= {handleLogin_Click}  /><Igd/><Footer/></>}></Route>

                     <Route path="/inpatient" element={<><NavBar HandleLogin_Click= {handleLogin_Click}  /><InPatient/><Footer/></>}></Route>

                     <Route path="/mother" element={<><NavBar HandleLogin_Click= {handleLogin_Click}  /><MotherChild/><Footer/></>}></Route>

                     <Route path="/lab" element={<><NavBar HandleLogin_Click= {handleLogin_Click}  /><Lab/><Footer/></>}></Route>


                     <Route path="/promotion" element={<><NavBar HandleLogin_Click= {handleLogin_Click}  /><UnderConstruction/><Footer/></>}></Route>

                     <Route path="/informationgallery" element={<><NavBar HandleLogin_Click= {handleLogin_Click}  /><InformationGallery/><Footer/></>}></Route>

                     <Route path="/videogallery" element={<><NavBar HandleLogin_Click= {handleLogin_Click}  /><VideoGallery/><Footer/></>}></Route>

                     <Route path="/bookingrule" element={<><NavBar HandleLogin_Click= {handleLogin_Click}  /><BookingRule/><Footer/></>}></Route>

                     <Route path="/contact" element={<><NavBar HandleLogin_Click= {handleLogin_Click}  /><Contact/><Footer/></>}></Route>

                     

                     <Route path="/career" element={<><NavBar HandleLogin_Click= {handleLogin_Click}  /><Career/><Footer/></>}></Route>

                     <Route path="/faq" element={<><NavBar HandleLogin_Click= {handleLogin_Click}  /><UnderConstruction/><Footer/></>}></Route>

                     <Route path="/questionaire" element={<><NavBar HandleLogin_Click= {handleLogin_Click}  /><UnderConstruction/><Footer/></>}></Route>






                     




                     
               </Routes>
               </div>

            </Router>

            </div>

            );
    }


// class App extends React.Component {
   
//     // Constructor 
//     constructor(props) {
//         super(props);
   
//         this.state = {
//             items: [],
//             DataisLoaded: false
//         };
//     }

//       componentDidMount() {
//         fetch(
// "https://jsonplaceholder.typicode.com/users")
//             .then((res) => res.json())
//             .then((json) => {
//                 this.setState({
//                     items: json,
//                     DataisLoaded: true
//                 });
//             })
//     }

//     render() {
//         const { DataisLoaded, items } = this.state;
//         if (!DataisLoaded) return <div>
//             <h1> Pleses wait some time.... </h1> </div> ;
   
//         return (
//         <div className = "App">
//             <h1> Fetch data from an api in react </h1>  {
//                 items.map((item) => ( 
//                 <ol key = { item.id } >
//                     User_Name: { item.username }, 
//                     Full_Name: { item.name }, 
//                     User_Email: { item.email } 
//                     </ol>
//                 ))
//             }
//         </div>
//     );
// }

// }





// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

export default App;
