import React from 'react';
import Logo_GH from '../assets/logoText.png';

//--COMPONENT
import Schedule from './Schedule.jsx';

//--STYLE
import './NavBar.css';

//--ICON
import IconButton from "@mui/material/IconButton";
import IconInstagram from "@mui/icons-material/Instagram";
import IconFacebook from '@mui/icons-material/Facebook';
import IconYouTube from '@mui/icons-material/YouTube';
import IconCall from '@mui/icons-material/Call';
import IconEmerCall from '@mui/icons-material/AddIcCall';
import IconArrowDown from '@mui/icons-material/KeyboardArrowDown';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from "react-router-dom";


class NavBar extends React.Component{
  constructor(prop){
    super(prop);
  }
  render(){
      return(
        <>
         <NavBarTop HandleLogin_Click={this.props.HandleLogin_Click}/>
         <NavBarBottom/>
        </>
      );
  }
}


class NavBarTop extends React.Component{
  constructor(prop){
    super(prop);
  }

  
  render(){
    return(
        <div className="NavContainer">
        <div className="NavTop">
          <div>
            <IconCall className="iconColor"/> 
            <span> Cutomer Care 0778 - 363 318</span>
          </div>

          <div>
            <IconEmerCall className="iconColor"/>
            <span> Emergency 0778 - 363 127</span>
          </div>

          <div className="Right">
            <IconButton aria-label="facebook" >
            <IconFacebook className="iconColor"/>
            </IconButton>

            <IconButton aria-label="instagram">
            <IconInstagram className="iconColor"/>
            </IconButton>

            <IconButton aria-label="youtube">
            <IconYouTube className="iconColor"/>
            </IconButton>

            {/* <Link to="/login"> */}
              <IconButton aria-label="login" onClick={this.props.HandleLogin_Click}>
              <PersonOutlineOutlinedIcon className="iconColor"/>
              </IconButton>
            {/* </Link> */}
          </div>
      </div>
      </div>


    );
  }
}

class NavBarBottom extends React.Component{
  constructor(prop){
    super(prop);
  }

  render(){
    // const buttons = ['Home', 'Temu Dokter', 'Tentang RSGH', 'Layanan', 'Informasi', 'Hubungi Kami', 'Karir'];
    // const menu = buttons.map((button, index) =>
    //   <div key={index} id={index}>
    //       <div  className="NavMenu">
    //         <div className="NavButton"> 
    //           {button} <IconArrowDown fontSize="small" /> 
    //         </div>
    //         <div className="dropdown-content">
    //           <a href="">Chemical Waste Disposal</a>
    //           <a href="">Waste Water Treatment</a>
    //         </div>
    //       </div>
        
    //   </div>          
    //   );
    
    return(
      <div className="NavContainer">
      <div className="NavBottom">

        <img src={Logo_GH} className="logo_gh" />
        <div className="Right" >
          <NavHome/>
          <NavTemuDokter/>
          <NavTentangRSGH/>
          <NavLayanan/>
          <NavInformasi/>
          <NavHubungi/>
          <NavKarier/>

        </div>
      </div>
      </div>
    );

  }
}

function NavHome (props){
  const navMenu =(
        <div  className="NavMenu">
          <div className="NavButton"> 
            <Link to="/">Home &nbsp;</Link>
          </div>
        </div>
    );

  return navMenu;
}


function NavTemuDokter (props){
  const navMenu =(
        <div className="NavMenu">
          <div className="NavButton" > 
            Temu Dokter <IconArrowDown fontSize="small" /> 
          </div>
          <div className="dropdown-content">
            <Link to="/schedule">Jadwal Dokter</Link>
            <Link to="/bookingrule">Pendaftaran Online</Link>
          </div>
        </div>
    );

    return navMenu;
}

function NavTentangRSGH (props){
  const navMenu =(
        <div  className="NavMenu">
          <div className="NavButton"> 
            Tentang RSGH <IconArrowDown fontSize="small" /> 
          </div>
          <div className="dropdown-content">
            <Link to="/profile">Profil</Link>
            <Link to="/partner">Mitra Kami</Link>
            <Link to="/award">Penghargaan</Link>
          </div>
        </div>
    );

    return navMenu;
}


function NavLayanan (props){
  const navMenu =(
        <div  className="NavMenu">
          <div className="NavButton"> 
            Layanan <IconArrowDown fontSize="small" /> 
          </div>
          <div className="dropdown-content">
          <Link to="/policlinic">Poliklinik</Link>
          <Link to="/igd">Intstalasi Gawat Darurat(IGD)</Link>
          <Link to="/inpatient">Rawat Inap</Link>
          <Link to="/mother">Ibu Anak</Link>
          <Link to="/lab">Unggulan</Link>
          </div>
        </div>
    );

    return navMenu;
}

function NavInformasi (props){
  const navMenu =(
        <div  className="NavMenu">
          <div className="NavButton"> 
            Informasi <IconArrowDown fontSize="small" /> 
          </div>
          <div className="dropdown-content">
            <Link to="/promotion">Promosi</Link>
            <Link to="/informationgallery">Berita dan Artikel</Link>
            <Link to="/videogallery">Video Kesehatan</Link>
          </div>
        </div>
    );

    return navMenu;
}


function NavHubungi (props){
  const navMenu =(
        <div  className="NavMenu">
          <div className="NavButton"> 
            Hubungi Kami <IconArrowDown fontSize="small" /> 
          </div>
          <div className="dropdown-content">
          <Link to="/contact">Kontak</Link>
          <Link to="/faq">FAQ</Link>
          <Link to="/questionaire">Kuisioner Layanan</Link>
          </div>
        </div>
    );

    return navMenu;
}

function NavKarier (props){
  const navMenu =(
        <div  className="NavMenu">
          <div className="NavButton"> 
          <Link to="/Career">
            Karier
            <AnnouncementIcon fontSize="small" style={{color:"rgb(210,80,80)", marginLeft:"4px"}} />
          </Link>

             
          </div>
        </div>
    );

    return navMenu;
}


export default NavBar
