import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

//FORM INPUT
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';


//MODAL 
import Modal from '@mui/material/Modal';

//ICON
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import CloseIcon from '@mui/icons-material/Close';


const InformationViewer = (props) => {
    const [currentIndex, setCurrentIndex ]= useState(0);
    const [data, setData ]= useState();

    const navigate = useNavigate();

    const width = 600; //photo width
    let position = 0;
    position -= width * currentIndex;
    let itemCount = props.articlePhoto.length;

    const HandleNext_Click = ()=>{
      let index = currentIndex  + 1;

      // if current index is more than item count then go back to first
      if(index > (itemCount - 1)){
           index = 0;
      }
      setCurrentIndex(index);
    }
  
    const HandlePrev_Click = ()=> {
        let index = currentIndex - 1;

        // if current index is more lest than first index then go to last item
        if(index < 0 ){
            index = itemCount - 1;
        }
        setCurrentIndex(index);
      }


    const handleLogin_Click = async () => {
        // const requestOptions = {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify({ 'id': nik, 'phone' : handphone })
        //   };
      
        //   const response = await fetch('https://127.0.0.1/grahahermine/android/get_bookingdata.php', requestOptions);
        //   const json = await response.json();
        //   if(json == false)
        //   {
        //       alert("handphone / nik error");
        //   }
        //   else
        //   {
        //     // setVerificationResponse(json);
        //     // navigate(`/bookingpatientdt/${row.id}&${selDateFormat}&${selTime}`);
        //     props.handleLogin();
        //     props.handleLogin_Close();
        //     navigate(`/patient/${json[0].id}&${json[0].phone}`);
        //   }
    }
      return(
        <Modal
          open={props.open}
          onClose={props.handleInformation_Close}  
          aria-labelledby="Infomation Viewer"
          aria-describedby="Infomation Viewer"
          className="ModalInformationStyle"
        >
  
          <div className="ModalInformationContainer">
              <div className = "TitleBar">
                  <IconButton aria-label="close" style={{borderRadius:"0"}} onClick={props.handleInformation_Close}>
                      <CloseIcon />    
                  </IconButton>
              </div>

              <div className="ControlContainer">
                  <IconButton onClick={HandlePrev_Click} style={{borderRadius:"0"}}>
                    <ArrowBackIosNewIcon />
                  </IconButton>

                  <div className="ArticlePhotoCarouselContainer">
                  {
                    props.articlePhoto.map(
                      (item,index)=>
                      {
                        const path = "assets/article/" + item.Photo;
                        // const image = require("../" + path); // <-- this is for build from source
                        // `url(${ image    })`                 // <--use in return block
                        
                        return(
                          <div key={index} className='ArticlePhoto'
                              style={{ left:(width * index) + position ,
                                        backgroundImage: `url( ${path} )`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                          </div>
                        )
                      })
                  }
                  </div>

                  <IconButton onClick={HandleNext_Click} style={{borderRadius:"0"}}>
                    <ArrowBackIosNewIcon style={{ transform: "rotate(180deg)"}}/>
                  </IconButton>
              </div>

              <div className="ModalContent">
                  <IconButton aria-label="like">
                      <FavoriteIcon />    
                  </IconButton>
                  
                  <IconButton aria-label="share">
                      <ShareIcon />
                  </IconButton>

                  <h3>{props.articleContent.Header}</h3>
                  <p className="Datetime">{props.articleContent.CreateDate}</p>
                  <p className="ContentParagraph">{props.articleContent.Content}</p>
              </div>

              
              
  
          </div>
        </Modal>
      );
   
  }
  
  
  export default InformationViewer;
  