import React, {useState, useEffect, useCallback} from 'react';
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import { Url } from '../global';

//COMPONENT

import IconButton from '@mui/material/IconButton';
import {HowToStep} from "../page/HowToStep";


//STYLE & ICON
import './BookingRule.css';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';


export const Awards = (props)=>{

const [awardsList, setAwardsList] = useState([]);


useEffect(async() => {
    // const response = await fetch('http://rsgrahahermine.id/android/get_awards.php');
    const response = await fetch(Url + 'grahahermine/android/get_awards.php');

    const json = await response.json();
    setAwardsList( json );
},[])

let ss1 = require('../assets/step/choose_doctor_ss.png');
let ss2 = require('../assets/step/choose_datetime_ss.png');
let ss3 = require('../assets/step/filldata_ss.png');
let ss4 = require('../assets/step/view_bookingno_ss.png');

    return(
        <div className="BookingRuleContainer" >
            
        <h2>Tata Cara Membuat Janji: </h2>
                <div className="BookingRuleBox">
                <HowToStep/>
                {/* expanded={expanded === 'panel1'} onChange={handleChange('panel1')} */}
                
                    <Accordion className="AccordionBox">
                        <AccordionSummary
                          className="AccordionSummary"
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <h3>
                            1. Pilih Dokter
                          </h3>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="BookingSS" style={{backgroundImage : `url(${ss1})`}} ></div>
                          <p>Pilihlah Dokter sesuai dengan keperluan anda</p>
                        </AccordionDetails>
                    </Accordion>


                    <Accordion className="AccordionBox">
                        <AccordionSummary
                          className="AccordionSummary"
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <h3>2. Pilih Tanggal dan Waktu</h3>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="BookingSS" style={{backgroundImage : `url(${ss2})`}} ></div>
                          <p>
                            Pilihlah tanggal dan jam praktek yang anda inginkan
                          </p>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion className="AccordionBox">
                        <AccordionSummary
                          className="AccordionSummary"
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <h3>
                            3. Masukkan Data Pasien
                          </h3>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="BookingSSL" style={{backgroundImage : `url(${ss3})`}} ></div>
                          <p>
                            Masukkan Data Identitas anda, dan masukkan nomor verifikasi, yang akan di kirim ke nomor hp anda
                          </p>
                          <p className="info">
                            <InfoTwoToneIcon/> &nbsp; data pribadi yang anda masukkan hanya digunakan untuk keperluan medis.
                          </p>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion className="AccordionBox">
                        <AccordionSummary
                          className="AccordionSummary"
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <h3>
                            4. Datang ke Rumah Sakit
                          </h3>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="BookingSS" style={{backgroundImage : `url(${ss4})`}} ></div>
                          <p>
                            Janji temu anda telah dibuat silahkan datang ke Rumah Sakit, dengan menunjukkan nomor janji temu yang ada di website / yang di kirim melalui email
                          </p>
                          <p className="info">
                            <InfoTwoToneIcon/> &nbsp; Janji temu via website hanya bisa dibuat di hari yang sama paling lambat 4 jam sebelum jadwal yang anda pilih
                          </p>
                        </AccordionDetails>
                    </Accordion>
                </div>

        </div>
        
    );

   

}


export default Awards;

