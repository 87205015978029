import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { Url } from '../global';

//FORM INPUT
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
import ContactMailTwoToneIcon from '@mui/icons-material/ContactMailTwoTone';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
//MODAL 
import Modal from '@mui/material/Modal';

const Login = (props) => {
    const [nik, setNik ]= useState('');
    const [handphone, setHandphone ]= useState('');
    const [data, setData ]= useState();
    const navigate = useNavigate();
    
    const handleNIK_Change = (event)=>{
        setNik(event.target.value);
    } 

    const handleHandphone_Change = (event)=>{
        setHandphone(event.target.value);
    }
    
    const handleLogin_Click = async () => {
        // alert(props.id);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'id': props.id, 'phone' : props.phone })
          };
      
          const response = await fetch(Url + '/android/get_bookingdata.php', requestOptions);
          const json = await response.json();
          if(json == false)
          {
              alert("handphone / nik error");
          }
          else
          {
            // setVerificationResponse(json);
            // navigate(`/bookingpatientdt/${row.id}&${selDateFormat}&${selTime}`);
            if(props.loginSource == 'icon'){
                props.handleLogin();
                props.handleLogin_Close();
                navigate(`/patient/${json[0].id}&${json[0].phone}`);
            }else
            {
                props.handleLogin();
                props.handleLogin_Close();
                //navigate(`/grahahermine/${json[0].id}&${json[0].phone}`);
            }
          }
    }

      return(
        <Modal
          open={props.openLogin}
          onClose={props.handleLogin_Close}  
          aria-labelledby="Login"
          aria-describedby="Login"
        >
  
          <div className="VerificationModal">
            <div> <VpnKeyTwoToneIcon style={{color: "var(--primary)"}}fontSize="large"/> &nbsp; &nbsp; <h3 style={{color: "var(--primary)" }}>Silahkan Login</h3></div>

              <div>
                  <TextField name='NIK' onChange={props.handleId_Change} value={props.id}  required label="NIK" size="small" className="textfield" style={{marginTop:"1em"}} InputLabelProps={{ shrink : true}} />
              </div>

              <div>
                  <TextField name='Handphone' onChange={props.handlePhone_Change} value={props.phone} required label="Handphone" size="small" className="textfield" style={{marginTop:"1em"}} InputLabelProps={{ shrink : true}} />
              </div>

              <div>
                  <Button onClick={handleLogin_Click} value={nik} variant="contained" style={{textTransform: "none", backgroundColor: "var(--primary)"}}>Masuk</Button> 
                  <Button onClick={props.handleLogin_Close} variant="outlined" style={{textTransform: "none", color: "var(--primary)", borderColor: "var(--primary)", marginLeft: "1em"}} >Kembali</Button>
              </div>
          </div>
        </Modal>
      );
   
  }
  
  
  export default Login;
  