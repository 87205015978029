import React, {useState, useEffect, useCallback} from 'react';
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import { Url } from '../global';

//COMPONENT
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

//STYLE & ICON
import './Contact.css';
import IconButton from "@mui/material/IconButton";
import IconFacebook from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import IconCall from '@mui/icons-material/Call';

export const Contact = (props)=>{
    const [data, setData] = React.useState([]);


    useEffect(async() => {
    // const requestOptions = {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({ 'articleId': articleId })
    // };
    
    
//    const response = await fetch('http://rsgrahahermine.id/android/get_profile.php');
    const response = await fetch(Url + 'grahahermine/android/get_profile.php');

    const json = await response.json();
    setData( json );

    },[])

    let background = require('../assets/contact.png');

    return(
        <div className="ContactContainer" >
            <div className="Top" >
                <div className="Left" >
                    <h2>Hubungi Pusat Informasi</h2>
                    <div className="CallRow">
                        {/* <IconButton aria-label="Location" >
                            <div className="icon"><LocationOnIcon className="iconColor"/></div>
                            <div className="text">
                               Cari di Peta
                            </div>
                        </IconButton> */}

                        <IconButton aria-label="Phone" className="CallButton" >
                            <a className="ButtonWrap" href="tel:+62778363318" >
                                <div className="icon"><IconCall className="iconColor"/></div>
                                <div className="text"> 0778 - 363 318 </div>
                            </a>

                        </IconButton>

                        <IconButton aria-label="Whatsapp" className="CallButton">
                            <a className="ButtonWrap" href="https://api.whatsapp.com/send?phone=083183734181&text=Halo,">
                                <div className="icon"><WhatsAppIcon className="iconColor"/></div>
                                <div className="text"> 0778 - 363 318 </div>
                            </a>

                        </IconButton>
           
                    </div>

                    <div>
                    {/* onChange={props.handlePhone_Change} value={props.phone} */}
                          <TextField name='Name'  label="Nama" size="small" className="contact_txt" />
                    </div>
                    <div>
                          <TextField name='Name'  label="Email anda" size="small" className="contact_txt" />
                    </div>
                    <div>
                          <TextField name='Name' label="Pesan" size="small" multiline rows ={5} className="contact_txt"   />
                    </div>
                    <div>
                         <Button variant="contained" style={{textTransform: "none", backgroundColor: "var(--primary)", float: "right"}} >Kirim Email</Button>
                    </div>
                    

                </div>

                <div className="Right"  style={{ backgroundImage: "url(" + background + ")"  }}>
                    {/* <p>{item.Content}</p> */}
                </div>
            </div>

                        {/* separation */}

            <div className="Bottom" >
                <div className="Left" >
			        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.15395735727!2d103.97162581475379!3d1.045610199237286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31d98d97e760a303%3A0xa5ff611215c417fb!2sRS.%20Graha%20Hermine!5e0!3m2!1sid!2sid!4v1652674260835!5m2!1sid!2sid"
                            width="100%"
                            height="400"
                            frameborder="0"
                            style={{border:"0"}}
                            allowfullscreen=""
                            aria-hidden="false"
                            tabindex="0">
                    </iframe>
                </div>

                <div className="Right">
                    <div className="FormWrapper">
                        <h1>Rumah Sakit Graha Hermine</h1>
                        
                        <p>
                        Komp. Ruko Asih Raya, Blok B No. 9-15  <br/>
                        Jl. Letjend Suprapto, Buliang,  Kec. Batu Aji, Kota Batam  <br/>
                        Kepulauan Riau 29424  <br/>
                        Cutomer Care : 0778 - 363 318 <br/>
                        Emergency : 0778 - 363 127 <br/>
                        Email : 			
                        </p>
                    </div>
                </div>

	    	</div>
		
            
        </div>
                                
                                
       
            


            
    );

}


export default Contact;

