import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import NavBar from './page/NavBar';
import Carousel from './page/Carousel';
import {Tabs} from "./page/Tab";
import Booking from "./page/Booking";
import BookingPatientDt from "./page/BookingPatientDt";
import PatientBookingHistory from "./page/PatientBookingHistory";

import App from './App';


// import Button from '@mui/material/Button';
// import { DataGrid, GridRowsProp, GridColDef  } from '@mui/x-data-grid';
// import type {} from '@mui/x-data-grid/themeAugmentation';
// import type {} from '@mui/x-data-grid-pro/themeAugmentation';

// import Container from '@mui/material/Container';
// import Stack from '@mui/material/Stack';
// import Grid from '@mui/material/Grid';
// import ResponsiveNavBar from './page/ResponsiveNavBar';
// import NavBar from './ResponsiveNavBar';
// import { Carousel } from 'antd';
// import 'antd/dist/antd.css';


//--COMPONENT
import Schedule from './page/Schedule.jsx';

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    Navigate
  } from "react-router-dom";

  import { GoogleOAuthProvider } from '@react-oauth/google';


    ReactDOM.render(
      <GoogleOAuthProvider clientId="280788290456-uu6uk54qso64fe1f4g4jao7n0801d879.apps.googleusercontent.com">
                      <App/>
      </GoogleOAuthProvider>
                    , document.querySelector('#root')
                    );


// class App extends React.Component {
   
//     // Constructor 
//     constructor(props) {
//         super(props);
   
//         this.state = {
//             items: [],
//             DataisLoaded: false
//         };
//     }

//       componentDidMount() {
//         fetch(
// "https://jsonplaceholder.typicode.com/users")
//             .then((res) => res.json())
//             .then((json) => {
//                 this.setState({
//                     items: json,
//                     DataisLoaded: true
//                 });
//             })
//     }

//     render() {
//         const { DataisLoaded, items } = this.state;
//         if (!DataisLoaded) return <div>
//             <h1> Pleses wait some time.... </h1> </div> ;
   
//         return (
//         <div className = "App">
//             <h1> Fetch data from an api in react </h1>  {
//                 items.map((item) => ( 
//                 <ol key = { item.id } >
//                     User_Name: { item.username }, 
//                     Full_Name: { item.name }, 
//                     User_Email: { item.email } 
//                     </ol>
//                 ))
//             }
//         </div>
//     );
// }

// }

